<template>
  <b-card>
    <b-card-title>{{ $t('devicegroups.deviceGroups') }}</b-card-title>
    <div class="d-flex justify-content-end align-items-center">
      <b-button v-b-modal.inputModal
        ><i class="fas fa-plus"></i> {{$t('devicegroups.new')}}</b-button
      >
    </div>
    <div class="accordion mt-2" role="tablist">
      <b-card
        v-for="group, index in changedGroups"
        :key="group.id"
        no-body
        class="mb-1"
      >
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button
            block
            v-b-toggle="`accordion-group-${group.id}`"
            variant="white"
            class="text-left"
            >{{ group.name }}</b-button
          >
        </b-card-header>
        <b-collapse
          :id="`accordion-group-${group.id}`"
          accordion="my-accordion"
          role="tabpanel"
          :visible="index === changedGroups.length - 1"
        >
          <b-card-body>
            <multi-device-select :deviceList.sync="group.devices" :hiddenDevices="getChangedGroupedDeviceIds" :disableInput="group.devices.length >= 5"/>
            <div class="d-flex justify-content-end align-items-center mt-2">
              <b-button @click="removeGroup(group)" variant="outline-danger"
                ><i class="fas fa-trash"></i> {{$t('devicegroups.remove', {name: group.name})}}</b-button
              >
            </div>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>
    <b-alert :show="changedGroups.length === 0">
      <h5>{{ $t('devicegroups.noGroups') }}</h5>
      <b-button v-b-modal.inputModal variant="outline-secondary" class="mt-2">
        <i class="fas fa-plus"></i> {{$t('devicegroups.new')}}</b-button
      >
    </b-alert>
    <div class="d-flex justify-content-end mt-3">
      <b-button @click="saveGroups" variant="primary" class="mr-1"
        ><i class="fas fa-save mr-2"></i>{{ $t("user.save") }}
      </b-button>
    </div>

    <text-input-modal
      modalId="inputModal"
      :title="$t('devicegroups.new')"
      :label="$t('devicegroups.enterName')"
      @ok="addGroup"
    />
  </b-card>
</template>

<script>
import MultiDeviceSelect from "./MultiDeviceSelect.vue";
import TextInputModal from "./TextInputModal.vue";

export default {
  components: {
    MultiDeviceSelect,
    TextInputModal,
  },
  props: {
    groups: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      changedGroups: this.groups,
    };
  },
  computed: {
    getChangedGroupedDeviceIds() {
      let groupedDeviceIds = [];
      this.changedGroups.forEach(g => {
        groupedDeviceIds = groupedDeviceIds.concat(g.devices)
      });
      return groupedDeviceIds;
    }
  },

  methods: {
    saveGroups() {
      this.$emit("save", this.changedGroups);
    },
    addGroup(group) {
      this.changedGroups.push({
        id: 'd'+Date.now(),
        name: group,
        devices: [],
      });
    },
    removeGroup(group) {
      this.changedGroups = this.changedGroups.filter((g) => g.id !== group.id)
    }
  },

  watch: {
    groups(newValue) {
      this.changedGroups = newValue;
    },
  },
};
</script>

<style>
</style>