<template>
  <b-form inline @submit.prevent="addDevice" class="d-flex justify-content-end">
    <vue-single-select
      class="mr-2 flex-fill"
      v-model="selectedDevice"
      :options="deviceList"
      option-label="title"
      :placeholder="$t('permissions.enterDeviceName')"
      :disabled="disabled"
    ></vue-single-select>

    <b-button variant="outline-secondary" pill type="submit" :disabled="disabled"
      ><i class="fas fa-plus mr-2"></i
      >{{ $t("permissions.addDevice") }}</b-button
    >
  </b-form>
</template>

<script>
import { mapGetters } from "vuex";
import VueSingleSelect from "vue-single-select";

export default {
  components: {
    VueSingleSelect,
  },
  props: {
    hiddenDevices: {
      type: Array,
      default: new Array([]),
    },
    disabled: {
      type: Boolean,
      default: () => false,
    }
  },
  computed: {
    deviceList() {
      return this.getRawNameList()
        .filter((d) => !this.hiddenDevices.includes(d.id));
    },
  },
  data() {
    return {
      selectedDevice: null,
    };
  },
  methods: {
    ...mapGetters("devices", ["getRawNameList"]),
    addDevice() {
      if (this.selectedDevice === null) return;
      this.$emit("selected", this.selectedDevice.id);
      this.selectedDevice = null;
    },
  },
};
</script>

<style>
</style>