<template>
  <b-container>
    <h1>{{ $t("profile.profile") }}</h1>
    <b-overlay :show="loading" spinner-variant="primary" no-wrap />
    <div class="pt-2">
      <b-card bg-variant="secondary" class="mt-1 shadow" v-if="user">
        <div class="text-light">
          <div class="d-flex">
            <b-avatar variant="primary" :text="initials" size="4rem" />
            <div class="ml-3 d-flex flex-column">
              <h3>
                {{ getAttribute(user, "firstName") || "-" }}
                {{ getAttribute(user, "lastName") || "-" }}
              </h3>
              <p class="">{{ user.name }}</p>
            </div>
          </div>
          <hr class="mx-4" />
          <div class="d-flex flex-column text-white-50">
            <span
              >{{ $t("user.lastLogin") }}:
              {{
                getAttribute(user, "lastLogin") == ""
                  ? $t("user.never")
                  : $d(
                      new Date(getAttribute(user, "lastLogin")),
                      "datetime-short"
                    )
              }}</span
            >
            <span v-if="getAttribute(user, 'created') != ''"
              >{{ $t("user.created") }}:
              {{
                $d(new Date(getAttribute(user, "created")), "datetime-short")
              }}</span
            >
          </div>
        </div>
      </b-card>
    </div>
    <div class="d-flex flex-md-row flex-column">
      <!-- User Data -->
      <b-card class="mt-2 mr-md-2 shadow flex-fill" v-if="showUser">
        <h2 class="card-title">
          <i class="fas fa-user-edit mr-2"></i>{{ $t("user.userData") }}
        </h2>
        <b-form @submit.prevent="submitUserData" v-if="showUser">
          <b-form-group
            :label="$t('user.username')"
            label-for="username"
            :description="$t('user.usernameCantBeChanged')"
          >
            <b-form-input
              id="username"
              v-model="userFormData.name"
              type="email"
              disabled
            ></b-form-input>
          </b-form-group>

          <b-form-group :label="$t('user.firstName')" label-for="firstName">
            <b-form-input
              id="firstName"
              v-model="userFormData.firstName"
              type="text"
              autocomplete="off"
            ></b-form-input>
          </b-form-group>

          <b-form-group :label="$t('user.lastName')" label-for="lastName">
            <b-form-input
              id="lastName"
              v-model="userFormData.lastName"
              type="text"
              autocomplete="off"
            ></b-form-input>
          </b-form-group>
          <div class="d-flex justify-content-end">
            <b-button type="submit" variant="primary"
              ><i class="fas fa-save mr-2"></i
              >{{ userFormButtonText }}</b-button
            >
          </div>
        </b-form>
      </b-card>
      <!-- Reset Password -->
      <b-card class="mt-2 shadow flex-fill" v-if="showUser">
        <h2 class="card-title">
          <i class="fas fa-key mr-2"></i>{{ $t("user.changePassword") }}
        </h2>
        <b-form @submit.prevent="submitPasswordChange" v-if="showUser">
          <b-form-group
            :label="$t('user.oldPassword')"
            label-for="oldPassword"
            :description="$t('user.enterCurrentPassword')"
          >
            <b-form-input
              id="oldPassword"
              v-model="resetPassword.oldPassword"
              type="password"
              autocomplete="off"
              :state="validateOldPassword"
            ></b-form-input>
            <b-form-invalid-feedback :state="validateOldPassword">
              <i class="fas fa-exclamation-circle mr-2"></i
              >{{ $t("user.passwordNotCorrect") }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            :label="$t('user.newPassword')"
            label-forpassword1="username"
          >
            <b-form-input
              id="password1"
              v-model="resetPassword.password1"
              type="password"
              autocomplete="off"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            :label="$t('user.confirmPassword')"
            label-for="confirmPassword"
          >
            <b-form-input
              id="confirmPassword"
              v-model="resetPassword.password2"
              type="password"
              :state="validatePassword"
              autocomplete="off"
            ></b-form-input>
            <b-form-invalid-feedback :state="validatePassword">
              <i class="fas fa-exclamation-circle mr-2"></i
              >{{ $t("user.passwordMustMatch") }}
            </b-form-invalid-feedback>
          </b-form-group>

          <div class="d-flex justify-content-end">
            <b-button
              type="submit"
              variant="primary"
              :disabled="!validatePassword"
              ><i class="fas fa-lock mr-2"></i
              >{{ $t("user.changePassword") }}</b-button
            >
          </div>
        </b-form>
      </b-card>
    </div>
    <device-group-manager
      @save="saveUserGroups"
      class="mt-2"
      :groups="userGroups"
    />
  </b-container>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import DeviceGroupManager from "@/components/DeviceGroupManager.vue";

export default {
  components: { DeviceGroupManager },
  name: "Profile",
  props: {},
  data() {
    return {
      loading: false,
      user: null,
      showUser: null,
      userFormData: {},
      userFormButtonText: this.$t("user.save"),
      resetPassword: {
        oldPassword: "",
        password1: "",
        password2: "",
      },
      validateOldPassword: null,
      userGroups: [],
    };
  },
  computed: {
    ...mapGetters("users", ["username"]),
    initials() {
      let fn = this.getAttribute(this.user, "firstName");
      let ln = this.getAttribute(this.user, "lastName");
      if (fn && ln) {
        return fn.charAt(0) + ln.charAt(0);
      } else {
        return this.username.charAt(0);
      }
    },
    validatePassword() {
      if (
        this.resetPassword.password1 == "" ||
        this.resetPassword.password1 == ""
      )
        return null;
      return this.resetPassword.password1 === this.resetPassword.password2;
    },
  },
  methods: {
    ...mapActions("alerts", ["fireAlert"]),
    ...mapActions('users', ['setUserGroups']),
    loadData() {
      this.loading = true;
      axios
        .get(`/my-devices/api/users/${encodeURI(this.username)}`)
        .then((res) => {
          this.user = res.data;
        })
        .catch(() => {
          this.user = null;
          this.fireAlert([
            this.$t("Error"),
            this.$t("user.errorLoadingData"),
            "danger",
          ]);
        })
        .finally(() => {
          this.loading = false;
          this.showUser = this.user;
          if (this.user) {
            this.showPermissions = this.user.permissions;
            this.userFormData.name = this.user["name"];
            this.userFormData.firstName = this.getAttribute(
              this.user,
              "firstName"
            );
            this.userFormData.lastName = this.getAttribute(
              this.user,
              "lastName"
            );

            let groupAttributes = this.getAttribute(
              this.user,
              "deviceGroupAttributes"
            );
            groupAttributes =
              groupAttributes != "" ? JSON.parse(groupAttributes) : [];

            this.userGroups = [];
            groupAttributes.forEach((name) => {
              this.userGroups.push(
                JSON.parse(this.getAttribute(this.user, name))
              );
            });
          }
        });
    },
    getAttribute(user, attName) {
      let attValue = "";
      user["attributes"].forEach((a) => {
        if (a["name"] === attName) {
          attValue = a["value"];
          return;
        }
      });
      return attValue;
    },
    submitUserData() {
      //submits the userdata to server
      this.userFormButtonText = this.$t("user.saving") + "...";
      let attributes = ["firstName", "lastName"];
      attributes.forEach((attribute) => {
        axios
          .post(
            `/my-devices/api/users/${encodeURI(this.username)}/attributes`,
            JSON.stringify({
              name: attribute,
              value: this.userFormData[attribute],
            })
          )
          .then(() => {
            this.loadData();
            this.userFormButtonText = "OK";
            setTimeout(
              (vm) => {
                vm.userFormButtonText = this.$t("user.save");
              },
              3000,
              this
            );
          })
          .catch((err) => {
            this.fireAlert([
              this.$t("Error"),
              this.$t("user.userdataNotChanged"),
              "danger",
            ]);
            console.error(err);
          });
      });
    },

    submitPasswordChange() {
      if (!this.validatePassword) return;

      // Create new axios instance to check, if the provided "old password" is correct.
      // If it is possible to change the password using the old one in the Authorization header, it was correct.
      let newAxios = axios.create();
      newAxios
        .patch(
          `/my-devices/api/users/${encodeURI(this.username)}`,
          JSON.stringify({ password: this.resetPassword.password1 }),
          {
            headers: {
              Authorization:
                "Basic " +
                new Buffer.from(
                  `${this.username}:${this.resetPassword.oldPassword}`
                ).toString("base64"),
            },
          }
        )
        .then(() => {
          this.fireAlert([
            this.$t("Success"),
            this.$t("user.passwordChanged"),
            "success",
          ]);
          this.resetPassword.oldPassword = "";
          this.resetPassword.password1 = "";
          this.resetPassword.password2 = "";
          this.validateOldPassword = null; // reset validation
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.validateOldPassword = false; // => mark input in red and display the invalid text
          } else {
            this.fireAlert([
              this.$t("Error"),
              this.$t("user.passwordNotChanged"),
              "danger",
            ]);
          }
        });
    },

    saveUserGroups(groups) {
      // get original group attributes
      let oldGroupAttributes = this.userGroups.map((group) => `group-${group.id}`);
      let groupAttributeNames = groups.map((group) => `group-${group.id}`);

      // get the unused group attributes (= deleted groups)
      oldGroupAttributes = oldGroupAttributes.filter((g) => !groupAttributeNames.includes(g))

      let requests = []
      // send groupAttributeNames
      requests.push(axios.post(`/my-devices/api/users/${encodeURI(this.username)}/attributes`,
          JSON.stringify({
            name: "deviceGroupAttributes",
            value: JSON.stringify(groupAttributeNames),
          })))
      // send groups
      for (let i = 0; i < groups.length; i++) {
        const group = groups[i]
        requests.push(axios.post(`/my-devices/api/users/${encodeURI(this.username)}/attributes`,
          JSON.stringify({
            name: `group-${group.id}`,
            value: JSON.stringify(group),
          })))
      }
      // delete unused groups
      oldGroupAttributes.forEach((oldGroup) => {
        requests.push(axios.delete(`/my-devices/api/users/${encodeURI(this.username)}/attributes/${oldGroup}`))
      })

      axios.all(requests)
        .then(() => {
          this.fireAlert([this.$t("Success"), this.$t("saved"), "success"]);
          this.loadData();
          this.setUserGroups([groups])
        })
        .catch((err) => {
          this.fireAlert([
            this.$t("Error"),
            this.$t("user.userdataNotChanged"),
            "danger",
          ]);
          console.error(err);
        });
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style scoped>
</style>