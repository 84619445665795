<template>
  <div>
    <single-device-select
      @selected="addDevice"
      :hiddenDevices="getHiddenDevices"
      :disabled="disableInput"
    ></single-device-select>
    <ul class="list-group" v-if="devices.length > 0">
      <li class="list-group-item" v-for="id in devices" :key="id">
        <div class="d-flex justify-content-between align-items-center">
          <span>{{ getDeviceNameFromId(id) }}</span>
          <b-button pill variant="outline-danger" @click="removeDevice(id)"
            ><i class="fas fa-trash"></i
          ></b-button>
        </div>
      </li>
    </ul>
    <b-alert :show="devices === null || devices.length == 0">
      {{ $t("no-devices") }}
    </b-alert>
  </div>
</template>

<script>
import SingleDeviceSelect from "./SingleDeviceSelect.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    SingleDeviceSelect,
  },
  props: {
    deviceList: {
      type: Array,
      default: null,
    },
    hiddenDevices: {
      type: Array,
      default: () => [],
    },
    disableInput: {
      type: Boolean,
      default: () => false,
    }
  },
  data() {
    return {
      devices: this.deviceList,
    };
  },
  computed: {
    ...mapGetters('devices', ['getDeviceNameFromId']),
    getHiddenDevices() {
      return this.devices.concat(this.hiddenDevices);
    }
  },
  methods: {
    addDevice(id) {
      this.devices.push(id);
      this.updateDeviceList(this.devices);
    },
    removeDevice(id) {
      this.devices = this.devices.filter((d) => d != id);
      this.updateDeviceList(this.devices);
    },
    updateDeviceList(newList) {
      this.$emit("update:deviceList", newList);
    },
  },
};
</script>

<style>
</style>